import * as React from "react"
import { Head } from "../components/head"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Header } from "../components/shared/type"

const CareersPage = (): JSX.Element => {
  return (
    <>
      <SEO title="Careers" />
      <Head title={"Careers"} />
      <Layout>
        <Title>Coming Soon.</Title>
      </Layout>
    </>
  )
}
export default CareersPage

const Title = styled(Header)`
  display: grid;
  justify-content: center;
  align-content: center;
  height: 70vh;
  color: var(--brand-green);
  font: 400 2.5rem/3rem var(--display-font);

  @media (min-width: 768px) {
    font: 400 3.5rem/5rem var(--display-font);
  }
`
